import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
// context
import { GlobalContext } from '../../layout'
// components
import Dropdown from '../dropdown'
import DropdownShop from '../dropdown-shop'
import DropdownExpand from '../dropdown-expand'
// services
import { logout } from '../../../services/auth'
import { isSSR } from '../../../services/tracking'
// styles
import styles from './navigation-item.module.scss'

interface IProps {
  item: any
}

const NavigationItem: React.FC<IProps> = ({ item }) => {
  const { setShowAddCoupon } = useContext(GlobalContext)

  const pathname = !isSSR && window.location.pathname

  const handleAction = (action: any) => {
    switch (action) {
      case 'addCoupon':
        setShowAddCoupon(true)
        break
      case 'logout':
        logout()
        navigate(`${process.env.GATSBY_HOST_NAME}`)
    }
  }

  return (
    <div>
      {item.actionType === 'navigate' && (
        <a
          className={item.hasDivider ? styles.navLinkDivider : styles.navLink}
          href={`${item.actionValue}`}
          target="_blank"
          rel="noopener"
        >
          <span className={styles.navLinkInner}>{item.label}</span>
        </a>
      )}
      {item.actionType === 'callback' && (
        <button
          className={item.hasDivider ? styles.navLinkDivider : styles.navLink}
          type="button"
          onClick={() => handleAction(item.actionValue)}
        >
          <span className={styles.navLinkInner}>{item.label}</span>
        </button>
      )}
      {item.actionType === 'dropdown' && (
        <Dropdown items={item.actionValue}>
          <div
            className={item.hasDivider ? styles.navLinkDivider : styles.navLink}
          >
            <span className={styles.navLinkInner}>{item.label}</span>
            <span className={styles.arrow}></span>
          </div>
        </Dropdown>
      )}
      {item.actionType === 'shop' && (
        <DropdownShop items={item.actionValue}>
          <div className={styles.navLink}>
            <span className={styles.navLinkInner}>{item.label}</span>
            <span className={styles.arrow}></span>
          </div>
        </DropdownShop>
      )}
      {item.actionType === 'expand' && (
        <DropdownExpand item={item}>
          <div className={styles.navLink}>
            <span className={styles.navLinkInner}>{item.label}</span>
            <span className={styles.arrow}></span>
          </div>
        </DropdownExpand>
      )}
    </div>
  )
}

export default NavigationItem
