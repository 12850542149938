import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { setCookie } from '../../services/cookies'
// context
import { GlobalContext } from '../layout'
// components
import ResponsiveResolutionImage from '../responsive-resolution-image'
// styles
import styles from './utm-modal.module.scss'

interface IProps {
  type: string
  image: string
  redirectPath: string
}

const UtmModal: React.FC<IProps> = ({ type, image, redirectPath }) => {
  const { setUtmModalData } = useContext(GlobalContext)

  const handleClose = () => {
    const cookieExpiry = 3 * 24 * 60 * 60 // 3 days
    setCookie('utm-modal-' + type, 'true', cookieExpiry)

    setUtmModalData(null)
  }

  const handleClick = (path: string) => {
    handleClose()
    navigate(path)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image} onClick={() => handleClick(redirectPath)}>
          <ResponsiveResolutionImage
            filename={image}
            width={375}
            lazyload={false}
          />
        </div>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={handleClose}
        >
          <img src={'/images/icon_close.svg'} alt="Close" />
        </button>
      </div>
    </div>
  )
}

export default UtmModal
