import React from 'react'
import { Link, navigate } from 'gatsby'
import CookieBanner from 'react-cookie-banner'

// styles
import styles from './cookies-banner.module.scss'

interface IProps {
  description: string
  buttonLabel: string
}

const CookiesBanner: React.FC<IProps> = ({ description, buttonLabel }) => (
  <CookieBanner
    cookiePath="/"
    cookie="cookies-accepted"
    dismissOnScroll={false}
    dismissOnClick={true}
  >
    {(onAccept: any) => (
      <div className={styles.cookieBanner}>
        <div className={styles.cookieBannerContainer}>
          <img src="/images/icon_cookies.svg" alt="" />
          <p>
            {description}{' '}
            <a
              className={styles.link}
              href={`${process.env.GATSBY_VITL_HOST_NAME}/privacy`}
              target="_blank"
              rel="noopener"
            >
              <span className="invisible-text">to view privacy policy</span>{' '}
              Learn more
            </a>
          </p>
          <button
            className={styles.cookieBannerButton}
            type="button"
            onClick={onAccept}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    )}
  </CookieBanner>
)

export default CookiesBanner
