import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import UtmModal from '../utm-modal'
import Header from '../header'
import Footer from '../footer'
import ModalAddCoupon from '../modal-add-coupon'
import ModalChangeCurrency from '../modal-change-currency'
import CookiesBanner from '../cookies-banner'
import Intercom from '../intercom'
// services
import { getToken } from '../../services/auth'
import { getCookie } from '../../services/cookies'
// styles
import 'lazysizes'
import '../../styles/normalize.scss'
import '../../styles/global.scss'
import styles from './layout.module.scss'

interface IProps {
  showHeader?: boolean
  showFooter?: boolean
  children: React.ReactNode
  showDiscountBanner?: boolean
  bannerType?: string
}

interface IGlobal {
  showCart: boolean
  setShowCart: any
  showAddCoupon: boolean
  setShowAddCoupon: any
  showUpsell: boolean
  setShowUpsell: any
  showChangeCurrency: boolean
  setShowChangeCurrency: any
  isLoggedIn: boolean
  setIsLoggedIn: any
  utmModalData: any
  setUtmModalData: any
}

// content Provider
export const GlobalContext = React.createContext<Partial<IGlobal>>({})

const Layout: React.FC<IProps> = ({ showFooter, showHeader, children }) => {
  const data = useStaticQuery(graphql`
    query SocialLinks {
      site {
        siteMetadata {
          title
        }
      }
      directusSetting {
        logo {
          title
          filename_disk
        }
        logo_primary {
          title
          filename_disk
        }
        navigation_links {
          label
          path
        }
        navigation_links_2 {
          label
          path
        }
        social_links_label
        social_links {
          icon
          url
        }
        sign_up_label
        sign_up_placeholder
        cookies_description
        cookies_button_label
      }
      allDirectusGenericPage(filter: { show_in_navigation: { eq: "footer" } }) {
        edges {
          node {
            path
            title
          }
        }
      }
      allDirectusModal {
        nodes {
          type
          redirect_path
          image {
            filename_disk
          }
        }
      }
    }
  `)

  const [showCart, setShowCart] = useState(false)
  const [showUpsell, setShowUpsell] = useState(false)
  const [showAddCoupon, setShowAddCoupon] = useState(false)
  const [showChangeCurrency, setShowChangeCurrency] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(getToken())
  const [utmBannerHeight, setUtmBannerHeight] = useState(0)
  const [utmBannerData, setUtmBannerData] = useState<any>(null)
  const [utmModalData, setUtmModalData] = useState<any>(null)
  const [showGoalChange, setShowGoalChange] = useState(false)

  const globalContext = {
    isLoggedIn,
    setIsLoggedIn,
    showCart,
    setShowCart,
    showAddCoupon,
    setShowAddCoupon,
    showUpsell,
    setShowUpsell,
    showChangeCurrency,
    setShowChangeCurrency,
    utmModalData,
    setUtmModalData,
    showGoalChange,
    setShowGoalChange,
  }

  useEffect(() => {
    const modalType = localStorage.getItem('utm_show_popup')
    const modalData = data.allDirectusModal.nodes.find(
      (modal: any) => modal.type === modalType
    )
    const isModalSeen = getCookie('utm-modal-' + modalType)

    if (!isModalSeen && modalData) {
      setUtmModalData(modalData)
    }
  }, [])

  return (
    <div>
      <GlobalContext.Provider value={globalContext}>
        {showHeader && (
          <Header
            siteTitle={data.site.siteMetadata.title}
            logo={data.directusSetting.logo}
          />
        )}

        <main
          className={showHeader ? styles.main : styles.mainNoPadding}
          style={{ marginTop: utmBannerHeight }}
        >
          {children}
        </main>
        {showFooter && (
          <Footer
            content={data.directusSetting}
            navItems={data.allDirectusGenericPage.edges}
          ></Footer>
        )}
        <div id="modalContainer"></div>
        <ModalAddCoupon />
        <ModalChangeCurrency />
        {/*{showUpsell && <ModalUpsell />}*/}

        {utmModalData && (
          <UtmModal
            type={utmModalData.type}
            image={utmModalData.image.filename_disk}
            redirectPath={utmModalData.redirect_path}
          />
        )}
        <CookiesBanner
          description={data.directusSetting.cookies_description}
          buttonLabel={data.directusSetting.cookies_button_label}
        />

        <Intercom />
      </GlobalContext.Provider>
    </div>
  )
}

Layout.defaultProps = {
  showHeader: true,
  showFooter: true,
}

export default Layout
