import gql from 'graphql-tag'

export const paymentMethodFragment = gql`
  fragment paymentMethod on PaymentMethod {
    id
    name
    type
    cardIssuer
    iconUrl
    lastFourDigits
    expiryMonth
    expiryYear
    postcode
    default
  }
`

export const PAYMENT_METHODS = gql`
  query user_paymentMethods {
    user_paymentMethods {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`

export const UPDATE_PAYMENT_METHOD = gql`
  mutation user_updatePaymentMethod(
    $id: ID!
    $postcode: String
    $setAsDefault: Boolean
  ) {
    user_updatePaymentMethod(
      id: $id
      postcode: $postcode
      setAsDefault: $setAsDefault
    ) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`

export const REMOVE_PAYMENT_METHOD = gql`
  mutation user_removePaymentMethod($id: ID!) {
    user_removePaymentMethod(id: $id) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`
