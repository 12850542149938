import React from 'react'
import { ApolloError } from 'apollo-client'
import styles from './error.module.scss'
// services
import { formatGraphQLError } from '../../services/format'

interface IProps {
  data: ApolloError | string | undefined
  noMargin: boolean
}

const Error: React.FC<IProps> = ({ data, noMargin }) => {
  return (
    <>
      {data && (
        <div className={noMargin ? styles.errorNoMargin : styles.error}>
          {formatGraphQLError(data)}
        </div>
      )}
    </>
  )
}

export default Error
