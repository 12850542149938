import React from 'react'
import Error from '../error/error'
import styles from './submit-button.module.scss'
import { Link } from 'gatsby'
import { getCookie } from '../../services/cookies'

interface IProps {
  loading?: boolean
  success?: any
  error?: any
  buttonLabel?: string
  errorMessage?: string
  successMessage?: string
  fullWidth?: boolean
  onClick?: any
  disabled?: any
}

const SubmitButton: React.FC<IProps> = ({
  loading,
  success,
  error,
  buttonLabel = 'Submit',
  errorMessage = 'There was an error.',
  successMessage = '',
  fullWidth,
  onClick,
  disabled,
}) => {
  return (
    <div>
      {!loading && success && successMessage !== '' && (
        <p className={styles.success}>{successMessage}</p>
      )}

      {!loading && <Error data={error} />}

      {loading && (
        <button
          type={onClick ? 'button' : 'submit'}
          className={fullWidth ? styles.buttonFullWidth : styles.button}
          disabled={loading || disabled}
          onClick={onClick}
        >
          Loading... <div className={styles.loader}></div>
        </button>
      )}

      {!loading && (
        <a
          onClick={onClick}
          onMouseDown={onClick}
          target="_blank"
          className={styles.link}
          rel="noopener"
        >
          <button
            type={onClick ? 'button' : 'submit'}
            className={fullWidth ? styles.buttonFullWidth : styles.button}
            disabled={loading || disabled}
            onClick={onClick}
          >
            {buttonLabel}
          </button>
        </a>
      )}
    </div>
  )
}

export default SubmitButton
