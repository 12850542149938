import React from 'react'

interface IProps {
  filename?: string
  url?: string
  title?: string
  width: number
  height?: number
  lazyload?: boolean
  className?: string
}

const ResponsiveResolutionImage: React.FC<IProps> = ({
  filename,
  url,
  title = '',
  className = '',
  width,
  height = '',
  lazyload = true,
}) => {
  const imgPath = url || `${process.env.GATSBY_MEDIA_URL}${filename}`

  if (lazyload) {
    return (
      <img
        width={width}
        height={height}
        className="lazyload"
        data-srcset={`
          ${imgPath}?w=${width} 1x,
          ${imgPath}?w=${width * 2} 2x
        `}
        data-src={`${imgPath}?w=${width}`}
        alt={title}
      />
    )
  }

  return (
    <img
      width={width}
      height={height}
      className={className}
      srcSet={`
        ${imgPath}?w=${width} 1x,
        ${imgPath}?w=${width * 2} 2x
      `}
      src={`${imgPath}?w=${width}`}
      alt={title}
    />
  )
}

export default ResponsiveResolutionImage
