import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
// styles
import styles from './dropdown-shop.module.scss'

interface IProps {
  children: any
  items: Array<any>
}

const DropdownShop: React.FC<IProps> = ({ children, items }) => {
  const location = useLocation()

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [activeCategoryID, setActiveCategoryID] = useState(0)

  useEffect(() => {
    document.body.classList.remove('body-fixed')
    setDropdownIsOpen(false)
  }, [location])

  useEffect(() => {
    if (dropdownIsOpen) {
      document.body.classList.add('body-fixed')
    } else {
      document.body.classList.remove('body-fixed')
    }
  }, [dropdownIsOpen])

  return (
    <div className={styles.dropdownWrapper}>
      <div className={styles.dropdownToggle}>{children}</div>

      <div className={styles.dropdown}>
        <div className={styles.shop}>
          <ul className={styles.categoriesList}>
            {items.map((item: any, i: number) => (
              <li key={i}>
                <div
                  className={
                    activeCategoryID === item.id
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                  onMouseEnter={() => setActiveCategoryID(item.id)}
                >
                  <span className={styles.navLinkInner}>{item.label}</span>
                </div>
              </li>
            ))}
          </ul>
          <ul className={styles.productsList}>
            {items.map((item: any, i: number) => (
              <li
                key={i}
                className={
                  activeCategoryID === item.id
                    ? styles.productsWrapperActive
                    : styles.productsWrapper
                }
              >
                <ul
                  className={
                    item.products.length < 4
                      ? styles.products
                      : styles.products5
                  }
                >
                  {item.products.map((product: any, i: number) => (
                    <li key={i} className={styles.product}>
                      <p>
                        <strong>{product.label}</strong>
                      </p>
                      <Link to={`/${product.route}`}>
                        <img
                          src={`${product.imgUrl}?v=1&width=336`}
                          alt={product.label}
                        />
                      </Link>
                      <p className={styles.productDescription}>
                        {product.description}
                      </p>
                      <Link className={styles.link} to={`/${product.route}`}>
                        <span className="invisible-text">{`Click here to learn more about ${item.label}`}</span>
                        Learn more
                      </Link>
                    </li>
                  ))}
                  {item.action && (
                    <li className={styles.productMore}>
                      <p className={styles.productDescription}>
                        {item.action.description}
                      </p>
                      <Link
                        className={styles.link}
                        to={`/${item.action.route}`}
                      >
                        {item.action.buttonLabel}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DropdownShop
