import React, { useState } from 'react'
import { Link } from 'gatsby'
// styles
import styles from './dropdown-expand.module.scss'

interface IProps {
  item: any
  children: any
}

const DropdownExpand: React.FC<IProps> = ({ item, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div onClick={() => setIsOpen(!isOpen)}>{children}</div>
      <ul className={isOpen ? styles.contentOpen : styles.content}>
        {item.products.map((item: any, i: number) => (
          <li key={i} className={styles.item}>
            <Link to={`/${item.route}`} className={styles.itemImage}>
              <img
                src={`${item.imgUrl}?v=1&width=336`}
                alt={item.label}
                className="lazyload"
              />
            </Link>
            <div className={styles.itemDetails}>
              <p className={styles.itemName}>{item.label}</p>
              <p>{item.description}</p>
              <Link to={`/${item.route}`} className={styles.link}>
                <span className="invisible-text">{`Click here to learn more about ${item.label}`}</span>
                Learn more
              </Link>
            </div>
          </li>
        ))}
        {item.action && (
          <li className={styles.itemMore}>
            <Link to={`/${item.action.route}`} className={styles.buttonGhost}>
              {item.action.buttonLabel}
            </Link>
          </li>
        )}
      </ul>
    </>
  )
}

export default DropdownExpand
