import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
// components
import NavigationItem from '../navigation-item'
// styles
import styles from './dropdown.module.scss'

interface IProps {
  children: any
  items: Array<any>
}

const Dropdown: React.FC<IProps> = ({ children, items }) => {
  const location = useLocation()

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  useEffect(() => {
    document.body.classList.remove('body-fixed')
    setDropdownIsOpen(false)
  }, [location])

  useEffect(() => {
    if (dropdownIsOpen) {
      document.body.classList.add('body-fixed')
    } else {
      document.body.classList.remove('body-fixed')
    }
  }, [dropdownIsOpen])

  return (
    <div className={styles.dropdownWrapper}>
      <div
        className={styles.dropdownToggle}
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
      >
        {children}
      </div>

      <ul className={dropdownIsOpen ? styles.dropdownOpen : styles.dropdown}>
        {items.map((item: any, i: number) => (
          <li key={i}>
            <NavigationItem item={item} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown
