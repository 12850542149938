import { getCookie, setCookie, removeCookie } from '../services/cookies'
import { client } from '../apollo/client'

export const setToken = (token: string) => {
  const tokenExpiry = 5 * 60 * 60 // 5h
  setCookie('token', token, tokenExpiry)
}

export const removeToken = () => {
  removeCookie('token')
  removeCookie('guestToken')
}

export const getToken = () => {
  const token = getCookie('token')
  return !!token
}

export const logout = () => {
  removeToken()
  client.clearStore()
}
