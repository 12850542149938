import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
// context
import { GlobalContext } from '../layout'
// services
import { trackEvent } from '../../services/tracking'
// interfaces
import { IImage } from '../../interfaces/image'
// styles
import styles from './footer.module.scss'
import { navigation_links, navigation_links_2 } from './data'

interface IProps {
  navItems: Array<{
    node: {
      path: string
      title: string
    }
  }>
  content: {
    logo: IImage
    logo_primary: IImage
    navigation_links: Array<{
      label: string
      path: string
    }>
    navigation_links_2: Array<{
      label: string
      path: string
    }>
    social_links_label: string
    social_links: Array<{
      icon: string
      url: string
    }>
    sign_up_label: string
    sign_up_placeholder: string
  }
}

const Footer: React.FC<IProps> = ({ content, navItems }) => {
  const { setShowAddCoupon, setShowChangeCurrency } = useContext(GlobalContext)

  // state
  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty },
  } = useForm()

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <img
              src={`${process.env.GATSBY_MEDIA_URL}${content.logo_primary.filename_disk}`}
              alt={content.logo.title}
            />
            <hr className={styles.hrMobile} />
          </div>

          <ul className={styles.navLinks}>
            {navigation_links.map(item => (
              <li key={item.path} className={styles.siteItem}>
                {item.path.startsWith('/') ? (
                  <Link
                    className={styles.link}
                    to={item.path}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <a
                    className={styles.link}
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.label}
                  </a>
                )}
              </li>
            ))}
          </ul>

          <hr className={styles.hrMobile} />

          <ul className={styles.navLinks}>
            <li className={styles.siteItem}>
              <button
                className={styles.link}
                type="button"
                onClick={() => setShowAddCoupon(true)}
              >
                Add promo code
              </button>
            </li>

            {navigation_links_2.map(item => (
              <li key={item.path} className={styles.siteItem}>
                <a
                  className={styles.link}
                  href={item.path}
                  target="_blank"
                  rel="noopener"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>

          <hr className={styles.hrMobile} />

          <div className={styles.rightCol}>
            <div className={styles.social}>
              <p className={styles.socialHeading}>
                {content.social_links_label}
              </p>
              <ul>
                {content.social_links.map(item => (
                  <li key={item.url} className={styles.socialItem}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener"
                      onClick={() => {
                        // tracking
                        trackEvent('Click CTA', {
                          cta_name: item.icon,
                          cta_category: 'social links',
                        })
                      }}
                    >
                      <img
                        src={`/images/icon_${item.icon}.svg`}
                        alt={item.icon}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className={styles.social}>
              <h5 className={styles.socialHeading}>{content.sign_up_label}</h5>
              <div className={styles.inputInline}>
                <div className={styles.input}>
                  <div className={isDirty ? styles.inputPlaceholderActive : styles.inputPlaceholder}>
                    {content.sign_up_placeholder}
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    ref={register({ required: true })}
                  />
                </div>
                <button type="submit">
                  <img src="/images/icon_chevron.svg" alt="submit" />
                </button>
              </div>
              {errors.email && <p className={styles.error}>Please enter your email address</p>}
            </div> */}
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.rowReverse}>
          <ul className={styles.bottomColLeft}>
            <li>
              <small className={styles.copyright}>
                © {new Date().getFullYear()} Vitl. All rights reserved
              </small>
            </li>
            <li>
              <button
                className={styles.link}
                onClick={() => setShowChangeCurrency(true)}
              >
                Change currency
              </button>
            </li>
          </ul>
          <hr className={styles.hrMobile} />
          <ul className={styles.bottomColRight}>
            <li className={styles.hippaComplaint}>
              <img src="/images/hipaa-light.png" alt="HIPAA compliant" />
            </li>
            <li>
              <a
                className={styles.link}
                href={`${process.env.GATSBY_VITL_HOST_NAME}/terms`}
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href={`${process.env.GATSBY_VITL_HOST_NAME}/privacy`}
                target="_blank"
                rel="noopener"
              >
                Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
