import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { CSSTransition } from 'react-transition-group'
// context
import { GlobalContext } from '../layout'
// components
import HeaderCart from './header-cart'
import NavigationItem from './navigation-item'
// interfaces
import { IImage } from '../../interfaces/image'
// styles
import styles from './header.module.scss'
import fadeTransition from '../../styles/fade-in.module.scss'

import Navigation from './data'

interface IProps {
  logo: IImage
  siteTitle: string
}

const Header: React.FC<IProps> = ({ logo, siteTitle }) => {
  const { bannerHeight } = useContext(GlobalContext)
  const location = useLocation()

  // state
  const [windowIsScrolled, setWindowIsScrolled] = useState(false)
  const [navType, setNavType] = useState('shopfront')
  const [isNavMobileOpen, setIsNavMobileOpen] = useState(false)

  const handleToggle = (isOpen: boolean) => {
    setIsNavMobileOpen(isOpen)

    if (isOpen) {
      document.body.classList.add('body-fixed')
    } else {
      document.body.classList.remove('body-fixed')
    }
  }

  const renderNavMobile = () => {
    return (
      <nav className={styles.navMobile}>
        <ul className={styles.navContainer}>
          {/*<li>/!*<HeaderCart />*!/</li>*/}
          <li className={styles.mobileIconHeader}>
            <a
              href={`${process.env.GATSBY_VITL_HOST_NAME}/myvitl`}
              target="_blank"
              rel="noopener"
            >
              <img
                src="/images/icon_cart.svg"
                alt="Vitl shop"
                width={20}
                height={17}
              />
            </a>
          </li>
          <li className={styles.logo}>
            <a
              href={`${process.env.GATSBY_HOST_NAME}`}
              target="_blank"
              rel="noopener"
            >
              <img
                width="60"
                src={`${process.env.GATSBY_MEDIA_URL}${logo.filename_disk}`}
                alt={siteTitle}
              />
            </a>
          </li>
          <li className={styles.mobileIconHeader}>
            <a
              href={`${process.env.GATSBY_VITL_HOST_NAME}/myvitl`}
              target="_blank"
              rel="noopener"
            >
              <img
                src="/images/icon-user.svg"
                alt="Vitl user profile"
                width={20}
                height={17}
              />
            </a>
          </li>
          {/*<li*/}
          {/*  className={*/}
          {/*    isNavMobileOpen ? styles.navToggleOpen : styles.navToggle*/}
          {/*  }*/}
          {/*  onClick={() => handleToggle(!isNavMobileOpen)}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    <span className={styles.navToggleInner}></span>*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>

        {/*<CSSTransition*/}
        {/*  in={isNavMobileOpen}*/}
        {/*  timeout={300}*/}
        {/*  classNames={{ ...fadeTransition }}*/}
        {/*  unmountOnExit*/}
        {/*>*/}
        {/*  <div className={styles.navMobileDropdown}>*/}
        {/*    <ul className={styles.navMobileDropdownContainer}>*/}
        {/*      {Navigation[navType].mobile.map((item: any, i: number) => (*/}
        {/*        <li key={i}>*/}
        {/*          <NavigationItem item={item} />*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</CSSTransition>*/}
      </nav>
    )
  }

  const renderNavDesktop = () => {
    return (
      <nav className={styles.navDesktop}>
        <ul className={styles.navContainer}>
          {Navigation[navType].desktop.left.map((item: any, i: number) => (
            <li key={i}>
              <NavigationItem item={item} />
            </li>
          ))}

          <li className={styles.logo}>
            <a
              href={`${process.env.GATSBY_VITL_HOST_NAME}`}
              target="_blank"
              rel="noopener"
            >
              <img
                width="60"
                src={`${process.env.GATSBY_MEDIA_URL}${logo.filename_disk}`}
                alt={siteTitle}
              />
            </a>
          </li>

          {/*<li>*/}
          {/*  <HeaderCart />*/}
          {/*</li>*/}

          {Navigation[navType].desktop.right.map((item: any, i: number) => (
            <li key={i}>
              <NavigationItem item={item} />
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  useEffect(() => {
    const onScroll = () => {
      setWindowIsScrolled(window.pageYOffset > 0)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [windowIsScrolled])

  useEffect(() => {
    const path = location.pathname.split('/')

    if (path[1] === 'account' && path[2] !== 'signin') {
      setNavType('account')
    } else {
      setNavType('shopfront')
    }

    document.body.classList.remove('body-fixed')
  }, [location])

  return (
    <header className={styles.header} style={{ top: bannerHeight }}>
      <div
        className={
          windowIsScrolled ? styles.navWrapperScrolled : styles.navWrapper
        }
      >
        {renderNavMobile()}
        {renderNavDesktop()}
      </div>
    </header>
  )
}

export default Header
