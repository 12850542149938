import { getCookie } from '../services/cookies'

export const formatPrice = (price: any, currency?: string) => {
  const currencyFromCookie = getCookie('currency')

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency || currencyFromCookie || 'GBP',
  }).format(price)
}

export const formatDate = (date: any) => {
  ;(date && date.toString().includes('-')) || date.toString().includes(':')
    ? (date = Date.parse(date))
    : (date = new Date(date * 1000))

  if (date) {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(date)
  }
}

export const formatUnixDate = (date: any) => new Date(date * 1000)

export const formatExpiryDate = (month: number, year: number) => {
  const formattedMonth = month.toString().length === 1 ? `0${month}` : month
  const formattedYear = year.toString().substr(year.toString().length - 2)

  return `${formattedMonth}/${formattedYear}`
}

export const formatGraphQLError = (error: any) =>
  error.toString().replace('Error: GraphQL error: ', '')
