import React from 'react'
import styles from './custom-input.module.scss'

interface IProps {
  id: string
  type?: string
  label: string
  register: () => void
  error?: any
  errorMessage?: string
  isDirty: boolean
  disabled?: boolean
}

const CustomInput: React.FC<IProps> = ({
  id,
  type = 'text',
  label,
  register,
  error,
  errorMessage,
  isDirty,
  disabled = false,
}) => (
  <div>
    <div className={!error ? styles.container : styles.containerError}>
      <input id={id} name={id} type={type} ref={register} disabled={disabled} />
      <label
        htmlFor={id}
        className={!isDirty ? styles.placeholder : styles.placeholderActive}
      >
        {label}
      </label>
    </div>
    {error && <small className={styles.error}>{errorMessage}</small>}
  </div>
)

export default CustomInput
