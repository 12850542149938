import React, { useContext, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useForm } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
// queries
import { CHECK_COUPON } from '../../queries/cart'
// context
import { GlobalContext } from '../layout'
// components
import Modal from '../modal'
import ModalHeader from '../modal/modal-header'
import ModalBody from '../modal/modal-body'
import SubmitButton from '../submit-button'
import CustomInput from '../form/custom-input'
// styles
import styles from './modal-add-coupon.module.scss'
import fadeTransition from '../../styles/fade.module.scss'

const ModalAddCoupon: React.FC = () => {
  const { showAddCoupon, setShowAddCoupon, setShowCart } = useContext(
    GlobalContext
  )
  const [couponCode, setCouponCode] = useState('')
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm()

  // queries
  const [
    checkCoupon,
    { data: dataCoupon, loading: loadingCoupon, called },
  ] = useLazyQuery(CHECK_COUPON, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res.cart_checkCoupon) {
        setShowAddCoupon(false)
        localStorage.setItem('couponCode', couponCode)
        setShowCart(true)
      }
    },
  })

  const onSubmit = (formData: any) => {
    setCouponCode(formData.couponCode)

    checkCoupon({
      variables: {
        couponCode: formData.couponCode,
      },
    })
  }

  return (
    <CSSTransition
      in={showAddCoupon}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setShowAddCoupon(false)}>
        <ModalHeader>
          <h2>Enter your promo code</h2>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <fieldset disabled={loadingCoupon}>
              <CustomInput
                id="couponCode"
                register={register({ required: true })}
                label="Promo code"
                isDirty={dirtyFields.couponCode}
                error={errors.couponCode}
                errorMessage="Please enter promo code"
              />
            </fieldset>
            <SubmitButton
              loading={loadingCoupon}
              error={dataCoupon && !dataCoupon.cart_checkCoupon}
              errorMessage="Your promo code is invalid. Please check it and try again."
              buttonLabel="Submit"
              fullWidth
            />
          </form>
        </ModalBody>
      </Modal>
    </CSSTransition>
  )
}

export default ModalAddCoupon
