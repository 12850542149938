import gql from 'graphql-tag'

const goalFragment = gql`
  fragment goal on HealthProfile_Goal {
    id
    name
    color
    healthPillar
    score
    selected
    description
    imageUrl
    tagline
    nutrientClaims {
      nutrientLabel
      claim
      amount
      unit
    }
  }
`

export const pillFragment = gql`
  fragment pill on HealthProfile_Pill {
    id
    name
    imageUrl
    nutrients {
      name
      amount
      unit
      ingredient
      nrv
    }
  }
`

const userDetailsFragment = gql`
  fragment userDetails on UserDetails {
    firstName
    lastName
    email
    phone
    dob
  }
`

const referralInfoFragment = gql`
  fragment referralInfo on ReferralInfo {
    linkURL
    successfulReferrals
    commissions {
      total
      pending
      redeemed
    }
  }
`

export const SIGN_IN = gql`
  mutation user_signIn($email: String!, $password: String!) {
    user_signIn(email: $email, password: $password) {
      token
      customerDataPlatformId
    }
  }
`

export const MAGIC_SIGN_IN = gql`
  mutation user_signIn($magicLinkToken: String!) {
    user_signIn(magicLinkToken: $magicLinkToken) {
      token
      customerDataPlatformId
    }
  }
`

export const SEND_MAGIC_LINK = gql`
  mutation user_sendMagicLink($email: String!, $destination: String) {
    user_sendMagicLink(email: $email, destination: $destination)
  }
`

export const RESET_PASSWORD = gql`
  mutation user_resetPassword($email: String!) {
    user_resetPassword(email: $email)
  }
`

export const COMPLETE_PASSWORD_RESET = gql`
  mutation user_completePasswordReset($password: String!, $token: String!) {
    user_completePasswordReset(password: $password, token: $token)
  }
`

export const UPDATE_PASSWORD = gql`
  mutation user_changePassword($oldPassword: String!, $newPassword: String!) {
    user_changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

export const REGISTER_USER = gql`
  mutation user_register(
    $email: String!
    $password: String!
    $firstName: String!
  ) {
    user_register(email: $email, password: $password, firstName: $firstName) {
      token
      customerDataPlatformId
    }
  }
`

export const USER_DETAILS = gql`
  query user_details {
    user_details {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`

export const UPDATE_USER_DETAILS = gql`
  mutation user_updateDetails(
    $firstName: String
    $lastName: String
    $phone: String
    $password: String
  ) {
    user_updateDetails(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
    ) {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`

export const UPDATE_USER_EMAIL = gql`
  mutation user_updateEmail($email: String!) {
    user_updateEmail(email: $email) {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`

export const RECOMMENDED_PRODUCTS = gql`
  query recommended_products {
    user_details {
      firstName
    }
    health_recommendedProducts {
      id
      name
      sku
      variant
      price
      currency
      pills {
        ...pill
      }
      action {
        value
      }
    }
  }
  ${pillFragment}
`

export const CONSULTATION_RESULTS = gql`
  query consultationResults {
    health_goals {
      ...goal
    }
    health_recommendedProducts {
      id
      name
      sku
      variant
      price
      currency
      pills {
        ...pill
      }
    }
  }
  ${goalFragment}
  ${pillFragment}
`

export const DASHBOARD = gql`
  query dashboard {
    user_details {
      firstName
    }
    health_recommendedProducts {
      id
      sku
      quarterlySku
      variant
      name
      subtype
      description
      imageUrl
      score
      price
      currency
      action {
        type
        value
      }
    }
  }
`

export const GOALS = gql`
  query health_goals {
    health_goals {
      ...goal
      issues {
        id
        name
        imageUrl
      }
    }
    user_details {
      firstName
    }
  }
  ${goalFragment}
`

export const SELECT_GOALS = gql`
  mutation user_selectGoals($ids: [String]) {
    user_selectGoals(ids: $ids) {
      ...goal
    }
  }
  ${goalFragment}
`

export const GOAL_ANNOUNCEMENTS = gql`
  query user_goalAnnouncements {
    user_goalAnnouncements {
      goals {
        ...goal
      }
      content {
        title
        subtitle
        description
        imageUrl
        buttons {
          title
          action {
            type
            value
          }
        }
      }
    }
  }
  ${goalFragment}
`

export const GOAL_DETAILS = gql`
  query health_goalDetails($id: ID!) {
    user_details {
      firstName
    }
    health_goalDetails(id: $id) {
      id
      name
      imageUrl
      issues {
        id
        name
        imageUrl
      }
      foods {
        id
        name
        imageUrl
        description
      }
      dietAdvice {
        id
        description
        imageUrl
      }
      pills {
        ...pill
      }
      lifestyleAdvice {
        id
        description
        imageUrl
      }
    }
    health_recommendedProducts {
      sku
      variant
    }
  }
  ${pillFragment}
`

export const SEND_FEEDBACK = gql`
  mutation user_sendFeedback($message: String!) {
    user_sendFeedback(message: $message)
  }
`

export const GET_USER_REFERRALS = gql`
  query user_referrals {
    user_referrals {
      ...referralInfo
    }
  }
  ${referralInfoFragment}
`

export const SET_REFERRER = gql`
  mutation user_referFriend($email: String!) {
    user_referFriend(email: $email)
  }
`

export const CLAIM_REFERRAL_CREDIT = gql`
  mutation user_convertReferralCredit($amount: Int!) {
    user_convertReferralCredit(amount: $amount) {
      accountBalance
      referralInfo {
        ...referralInfo
      }
    }
  }
  ${referralInfoFragment}
`

export const GET_ACCOUNT_BALANCE = gql`
  query user_accountBalance {
    user_accountBalance
  }
`

export const EMAIL_PREFERENCES = gql`
  query get_email_preferences {
    user_subscribedToEmails
  }
`

export const USER_ADD_ATTRIBUTION = gql`
  mutation user_addAttribution(
    $clientId: String!
    $campaign: String
    $source: String
    $medium: String
    $content: String
    $term: String
  ) {
    user_addAttribution(
      clientId: $clientId
      campaign: $campaign
      source: $source
      medium: $medium
      content: $content
      term: $term
    )
  }
`

export const CONTENT_SCREEN = gql`
  query content_getScreen($path: String!) {
    content_getScreen(path: $path) {
      title
      jsonStyle
      jsonSections
      canNavigateBack
    }
  }
`

export const KIT_CAN_REGISTER = gql`
  query kit_canRegister($type: String!, $barcode: String!) {
    kit_canRegister(type: $type, barcode: $barcode)
  }
`

export const REGISTER_KIT = gql`
  mutation user_registerKit(
    $type: String!
    $barcode: String!
    $firstName: String!
    $lastName: String!
    $biologicalSex: String!
    $phone: String!
    $dob: Int!
  ) {
    user_registerKit(
      type: $type
      barcode: $barcode
      firstName: $firstName
      lastName: $lastName
      biologicalSex: $biologicalSex
      phone: $phone
      dob: $dob
    )
  }
`

export const USER_COMMUNICATION_PREFERENCES = gql`
  query user_communicationPreferences {
    user_communicationPreferences {
      id
      title
      subtitle
      subscribed
      allowUnsubscribe
    }
    user_details {
      email
    }
  }
`

export const USER_SUBSCRIBED_TO_MARKETING_EMAILS = gql`
  query user_subscribedToEmails {
    user_subscribedToEmails
  }
`

export const USER_UPDATE_EMAIL_SUBSCRIPTION = gql`
  mutation user_updateEmailSubscription($enabled: Boolean!) {
    user_updateEmailSubscription(enabled: $enabled)
  }
`

export const USER_UPDATE_COMMUNICATION_PREFERENCES = gql`
  mutation user_updateCommunicationPreferences(
    $preferences: [UpdateCommunicationPreferencesData]
  ) {
    user_updateCommunicationPreferences(preferences: $preferences) {
      id
      title
      subtitle
      subscribed
      allowUnsubscribe
    }
  }
`
