import { client } from '../apollo/client'
import { getToken } from './auth'
// queries
import { USER_ADD_ATTRIBUTION } from '../queries/user'

const getUtmParams = () => {
  const params: any = {}

  Object.keys(localStorage)
    .filter(key => key.startsWith('utm_'))
    .map(key => {
      params[key.replace('utm_', '')] = localStorage.getItem(key)
    })

  return params
}

export const isSSR = typeof window === 'undefined'

export const trackIdentify = (id: string) => {
  if (!isSSR && window.rudderanalytics) {
    window.rudderanalytics.identify(id)

    client.mutate({
      mutation: USER_ADD_ATTRIBUTION,
      variables: {
        clientId: window.rudderanalytics.getAnonymousId(),
        ...getUtmParams(),
      },
    })
  }
}

export const trackPageView = (name: string) => {
  !isSSR &&
    window.rudderanalytics &&
    window.rudderanalytics.page(name, {
      title: name,
    })
}

export const trackEvent = (name: string, options?: any) => {
  !isSSR &&
    window.rudderanalytics &&
    window.rudderanalytics.track(name, options)
}

export const trackProductViewed = ({ name, url, product_id, category }) => {
  trackEvent('Product Viewed', {
    name,
    url,
    product_id,
    category,
  })
}

export const trackCheckoutStepViewed = ({
  step,
  cartId,
}: {
  step: number
  cartId: string
}) => {
  trackEvent('Checkout Step Viewed', {
    checkout_id: cartId,
    step,
  })
}

export const trackFormErrors = (form_name: string, errors: any) => {
  if (Object.keys(errors).length > 0) {
    const compiledErrors: any = []

    Object.entries(errors).map((error: any) => {
      const fieldName = error[0]
      const errorType = error[1].type

      compiledErrors.push({
        field_name: fieldName,
        error_type: errorType,
      })
    })

    !isSSR &&
      window.rudderanalytics &&
      window.rudderanalytics.track('Error Form', {
        form_name,
        compiledErrors,
      })
  }
}
