import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { trackPageView } from '../services/tracking'

interface IProps {
  title: string
  description: string
}

const SEO = ({ title, description }: IProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
          }
        }
      }
    `
  )

  const { defaultTitle, defaultDescription } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  }

  useEffect(() => {
    trackPageView(title)
  }, [])

  return (
    <Helmet
      title={seo.title || 'siemka'}
      htmlAttributes={{
        lang: 'en',
      }}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=5',
        },
        {
          name: `robots`,
          content: `max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
        },
      ]}
    ></Helmet>
  )
}

export default SEO
