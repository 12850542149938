export const navigation_links_2 = [
  {
    label: 'Vitamins',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/products/vitamins/`,
  },
  {
    label: 'DNA',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/product/dna/`,
  },
  {
    label: 'Blood',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/product/blood/`,
  },
  {
    label: 'Register your kit',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/kit/`,
  },
  {
    label: 'Practitioners',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/practitioners/`,
  },
  {
    label: 'Student discount',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/blog/article/student-discount`,
  },
]

export const navigation_links = [
  {
    label: 'Vitl Life Blog',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/blog`,
  },
  {
    label: 'Science',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/science/`,
  },
  {
    label: 'My Vitl',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/myvitl`,
  },
  {
    label: 'Contact us & FAQs',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/contact/`,
  },
  {
    label: 'Careers',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/vitl/`,
  },
  {
    label: 'About us',
    path: `${process.env.GATSBY_VITL_HOST_NAME}/about/`,
  },
]
