import React from 'react'
// styles
import styles from './modal-body.module.scss'

interface IProps {
  type?: string
  children: any
}

const ModalBody: React.FC<IProps> = ({ type = '', children }) => {
  return <div className={styles['content' + type]}>{children}</div>
}

export default ModalBody
