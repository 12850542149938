const shopCategories = [
  {
    id: 'personalised',
    label: 'Personalised',
    actionType: 'expand',
    products: [
      {
        label: 'Personalised Pack',
        imgUrl: 'https://static-prod.vitl.com/images/nav/personalised.jpg',
        description:
          '4 daily supplements formulated by experts based on your diet and lifestyle.',
        route: 'product/personalised',
      },
      {
        label: 'Essential One',
        imgUrl: 'https://static-prod.vitl.com/images/nav/essential-one.jpg',
        description:
          'A single personalised daily multivitamin covering the essentials.',
        route: 'product/essential-one',
      },
    ],
    action: {
      description:
        'Uniquely formulated to ensure you have the best start to the day, every day.',
      buttonLabel: 'Compare products',
      route: 'compare-personalised-products',
    },
  },
  {
    id: 'limited-edition',
    label: 'Limited Edition',
    actionType: 'expand',
    products: [
      {
        label: 'Christmas Gifts',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/xmas-gifts-new-amend.png',
        description:
          'Give the gift of better health this Christmas with our best gifts to give your friends and family.',
        route: 'products/christmas-gifts',
      },
      {
        label: 'Mind & Body',
        imgUrl: 'https://static-prod.vitl.com/images/nav/mind-and-body.jpg',
        description:
          'A limited edition Mind & Body pack designed to help you, look, feel, focus, & sleep better!',
        route: 'product/mind-and-body',
      },
    ],
  },
  {
    id: 'health-goals',
    label: 'Health Goals',
    actionType: 'expand',
    products: [
      {
        label: 'Skin & Beauty',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/SkinBeauty-image-D2x.png',
        description: 'Supports the maintenance of normal skin, hair & nails.',
        route: 'product/skin-and-beauty',
      },
      {
        label: 'Rise & Energise',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/RiseEnergise-image-D2x.png',
        description: 'To support your energy throughout the day.',
        route: 'product/rise-and-energise',
      },
      {
        label: 'Sweet Sleep',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/SweetSleep-image-D2x.png',
        description: 'To help you prepare for a deep and restful sleep.',
        route: 'product/sweet-sleep',
      },
      {
        label: 'Daily Biotic',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/DailyBiotic-image-D2x.png',
        description: 'Good bacteria to help keep your gut healthy.',
        route: 'product/daily-biotic',
      },
      {
        label: 'Immune Support',
        imgUrl: 'https://static-prod.vitl.com/images/nav/Immune-image-D2x.png',
        description: "To help reinforce your body's natural defences.",
        route: 'product/immune-support',
      },
    ],
  },
  {
    id: 'multi-vitamins',
    label: 'Multivitamins',
    actionType: 'expand',
    products: [
      {
        label: 'Female Multivitamin',
        imgUrl: 'https://static-prod.vitl.com/images/nav/F-Multi-image-D2x.png',
        description:
          'A comprehensive daily multivitamin to support female wellbeing.',
        route: 'product/female-multivitamin',
      },
      {
        label: 'Male Multivitamin',
        imgUrl: 'https://static-prod.vitl.com/images/nav/M-Multi-image-D2x.png',
        description:
          'A comprehensive daily multivitamin to support male wellbeing.',
        route: 'product/male-multivitamin',
      },
      {
        label: 'Kids Multivitamin',
        imgUrl:
          'https://static-prod.vitl.com/images/nav/nav-kids-multivits-retina.jpeg',
        description:
          'A daily tasty multivitamin gummy to support overall kids wellbeing.',
        route: 'product/kids-multivitamin',
      },
    ],
  },
  {
    id: 'womens-health',
    label: "Women's Health",
    actionType: 'expand',
    products: [
      {
        label: 'Fertility Pack',
        imgUrl: 'https://static-prod.vitl.com/images/nav/fertility.jpg',
        description: 'To prepare your body for a healthy conception.',
        route: 'product/fertility',
      },
      {
        label: 'Pregnancy Pack',
        imgUrl: 'https://static-prod.vitl.com/images/nav/pregnancy.jpg',
        description:
          'To support you and your growing baby through each stage of your pregnancy.',
        route: 'product/pregnancy',
      },
      {
        label: 'Postnatal Pack',
        imgUrl: 'https://static-prod.vitl.com/images/nav/breastfeeding.jpg',
        description:
          'To support your wellbeing as your body recovers into convenient daily strips.',
        route: 'product/postnatal',
      },
      {
        label: 'Menopause Pack',
        imgUrl: 'https://static-prod.vitl.com/images/nav/menopause.jpg',
        description:
          'To help you feel more comfortable as your body makes its menopausal adjustments.',
        route: 'product/menopause',
      },
    ],
    action: {
      description:
        'Supplements suited specifically to women, from prenatal support all the way through to managing the menopause.',
      buttonLabel: 'See all products',
      route: 'products/womens-health',
    },
  },
  {
    id: 'pocket-packs',
    label: 'Pocket Packs',
    actionType: 'expand',
    products: [
      // {
      //   label: 'CBD Gummies',
      //   imgUrl: 'https://static-prod.vitl.com/images/nav/cbd-gummies.png?v=1',
      //   description:
      //     'Formulated with premium CBD from natural organic hemp extract to help you find your inner zen.',
      //   route: 'product/cbd-gummies-tin',
      // },
      {
        label: 'Immune Support',
        imgUrl: 'https://static-prod.vitl.com/images/nav/immunity.jpg?v=2',
        description:
          'High strength antioxidants with natural plant extracts to support a healthy immune system.',
        route: 'product/immune-support-tin',
      },
      {
        label: 'Vitamin D',
        imgUrl: 'https://static-prod.vitl.com/images/nav/vitamin-d.jpg',
        description:
          'To support healthy bones, teeth, muscles and immune function.',
        route: 'product/vitamin-d',
      },
      {
        label: 'Omega 3',
        imgUrl: 'https://static-prod.vitl.com/images/nav/omega-3.jpg',
        description:
          'To support blood pressure, vision, normal heart, brain and liver function.',
        route: 'product/omega-3',
      },
      {
        label: 'Vegan Omega 3',
        imgUrl: 'https://static-prod.vitl.com/images/nav/vegan-omega-3.jpg?v=2',
        description:
          "Support your body's heart and brain health with vegan omega 3 algae oil, straight from the source.",
        route: 'product/vegan-omega-3',
      },
    ],
  },
  {
    id: 'dna-tests',
    label: 'DNA Tests',
    actionType: 'expand',
    products: [
      {
        label: 'DNA Nutrition Test',
        imgUrl: 'https://static-prod.vitl.com/images/nav/dna.jpg',
        description:
          'Discover how to feel your best with personalised diet, exercise & lifestyle advice based on your DNA.',
        route: 'product/dna',
      },
      // More info: https://vitlicious.atlassian.net/browse/V4-1178
      // {
      //   label: 'Vitamin DNA Test',
      //   imgUrl: 'https://static-prod.vitl.com/images/nav/dna-vitamin.jpg?v=2',
      //   description: 'Discover which key nutrients you\'re more likely to need to supplement with according to your DNA.',
      //   route: 'product/dna-vitamin-test'
      // }
    ],
    // action: {
    //   description: 'Optimise your wellbeing with personalised dietary, fitness and lifestyle advice based on your genetics.',
    //   buttonLabel: 'Compare products',
    //   route: 'products/dna'
    // }
  },
  {
    id: 'vitamin-tests',
    label: 'Blood Tests',
    actionType: 'expand',
    products: [
      {
        label: 'Vitamin & Cholesterol Blood Test',
        imgUrl: 'https://static-prod.vitl.com/images/nav/blood.jpg',
        description: 'Check and track your vitamins and cholesterol levels.',
        route: 'product/blood',
      },
    ],
  },
]

const navigationShopfront = {
  left: [
    {
      label: '',
      actionType: '',
      actionValue: '',
    },
    {
      label: 'Shop',
      actionType: 'navigate',
      actionValue: process.env.GATSBY_VITL_HOST_NAME,
    },
  ],
  right: [
    {
      label: '',
      actionType: '',
      actionValue: '',
    },
    {
      label: 'Account',
      actionType: 'navigate',
      actionValue: `${process.env.GATSBY_VITL_HOST_NAME}/myvitl`,
    },
  ],
}

const navigationAccount = {
  left: [
    {
      label: 'My Vitl',
      actionType: 'navigate',
      actionValue: '/account/dashboard',
    },
    {
      label: 'View Results',
      actionType: 'dropdown',
      hasDivider: true,
      actionValue: [
        {
          label: 'Consultation',
          actionType: 'navigate',
          actionValue: '/account/result',
          hasDivider: false,
        },
        {
          label: 'Blood Test',
          actionType: 'navigate',
          actionValue: '/account/result/blood',
          hasDivider: false,
        },
        {
          label: 'DNA Test',
          actionType: 'navigate',
          actionValue: '/account/result/dna',
          hasDivider: false,
        },
      ],
    },
  ],
  right: [
    {
      label: 'Account',
      actionType: 'dropdown',
      actionValue: [
        {
          label: 'Personal Details',
          actionType: 'navigate',
          actionValue: '/account/details',
          hasDivider: true,
        },
        {
          label: 'Orders',
          actionType: 'navigate',
          actionValue: '/account/orders',
          hasDivider: false,
        },
        {
          label: 'Subscriptions',
          actionType: 'navigate',
          actionValue: '/account/subscriptions',
          hasDivider: false,
        },
        {
          label: 'Payment Methods',
          actionType: 'navigate',
          actionValue: '/account/payment',
          hasDivider: false,
        },
        {
          label: 'Use Promo Code',
          actionType: 'callback',
          actionValue: 'addCoupon',
          hasDivider: true,
        },
        {
          label: 'Referral Rewards',
          actionType: 'navigate',
          actionValue: '/account/referral',
          hasDivider: false,
        },
        {
          label: 'Register Kit',
          actionType: 'navigate',
          actionValue: '/kit',
          hasDivider: true,
        },
        {
          label: 'Feedback',
          actionType: 'navigate',
          actionValue: '/account/feedback',
          hasDivider: true,
        },
        {
          label: 'Help',
          actionType: 'navigate',
          actionValue: '/contact',
          hasDivider: false,
        },
        {
          label: 'Logout',
          actionType: 'callback',
          actionValue: 'logout',
          hasDivider: false,
        },
      ],
    },
  ],
}

const Navigation: any = {
  shopfront: {
    mobile: [
      navigationShopfront.left[0],
      ...navigationShopfront.left[1].actionValue,
      ...navigationShopfront.right,
    ],
    desktop: navigationShopfront,
  },
  account: {
    mobile: [
      ...navigationAccount.left,
      ...navigationAccount.right[0].actionValue,
    ],
    desktop: navigationAccount,
  },
}

export default Navigation
