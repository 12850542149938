import React, { useContext, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
// context
import { GlobalContext } from '../layout'
import { getCookie, setCookie, removeCookie } from '../../services/cookies'
// components
import Modal from '../modal'
import ModalHeader from '../modal/modal-header'
import ModalBody from '../modal/modal-body'
// styles
import styles from './modal-change-currency.module.scss'
import fadeTransition from '../../styles/fade.module.scss'

const ModalChangeCurrency: React.FC = () => {
  const { showChangeCurrency, setShowChangeCurrency } = useContext(
    GlobalContext
  )
  const [chosenCurrency, setChosenCurrency] = useState(getCookie('currency'))

  const setCurrency = (currency: string) => {
    const cookieExpiry = 30 * 24 * 60 * 60 // 30 days
    removeCookie('currency')
    setCookie('currency', currency, cookieExpiry)
    setChosenCurrency(currency)
    setShowChangeCurrency(false)
    window.location.reload()
  }

  const getClassName = (currency: string) =>
    currency === chosenCurrency ? styles.chosenCurrency : styles.currency

  return (
    <CSSTransition
      in={showChangeCurrency}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setShowChangeCurrency(false)}>
        <ModalHeader>
          <h3>Select your currency</h3>
          <hr />
        </ModalHeader>
        <ModalBody>
          <div className={styles.wrapper}>
            <ul className={styles.currencyList}>
              <li
                onClick={() => setCurrency('GBP')}
                className={getClassName('GBP')}
              >
                <div>GBP</div>
                <div className={styles.currencySymbol}>£</div>
              </li>
              <li
                onClick={() => setCurrency('EUR')}
                className={getClassName('EUR')}
              >
                <div>EUR</div>
                <div className={styles.currencySymbol}>€</div>
              </li>
              <li
                onClick={() => setCurrency('USD')}
                className={getClassName('USD')}
              >
                <div>USD</div>
                <div className={styles.currencySymbol}>$</div>
              </li>
              <li
                onClick={() => setCurrency('AUD')}
                className={getClassName('AUD')}
              >
                <div>AUD</div>
                <div className={styles.currencySymbol}>$</div>
              </li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  )
}

export default ModalChangeCurrency
