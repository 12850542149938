import gql from 'graphql-tag'
import { paymentMethodFragment } from './payment'

const deliveryAddressFragment = gql`
  fragment deliveryAddress on Cart_Details {
    deliveryAddress {
      id
      firstName
      lastName
      street
      city
      postcode
      region
    }
  }
`

const cartDetailsFragment = gql`
  fragment cartDetails on Cart_Details {
    cartId
    estimatedDelivery
    deliveryAddress {
      id
      firstName
      lastName
      street
      city
      postcode
      region
    }
  }
`

const cartContentFragment = gql`
  fragment cartContent on Cart_Content {
    cartId
    items {
      id
      name
      description
      image
      qty
      cost
    }
    itemCount
    currency
    subTotal
    discountAmount
    discountCode
    shipping
    tax
    total
  }
`

export const CART = gql`
  query cart($id: ID) {
    cart(id: $id) {
      details {
        ...cartDetails
      }
      content {
        ...cartContent
      }
      payment {
        id
        cardIssuer
        iconUrl
        lastFourDigits
      }
    }
  }
  ${cartDetailsFragment}
  ${cartContentFragment}
`

export const ADD_ITEM_TO_CART = gql`
  mutation cart_addItem($cartId: ID, $sku: ID!, $quantity: Int!) {
    cart_addItem(cartId: $cartId, sku: $sku, quantity: $quantity) {
      ...cartContent
    }
  }
  ${cartContentFragment}
`

export const REMOVE_ITEM_FROM_CART = gql`
  mutation cart_removeItem($cartId: ID, $itemId: ID!) {
    cart_removeItem(cartId: $cartId, itemId: $itemId) {
      ...cartContent
    }
  }
  ${cartContentFragment}
`

export const SET_CART_ITEM_QTY = gql`
  mutation cart_setItemQty($itemId: ID!, $quantity: Int!, $cartId: ID) {
    cart_setItemQty(itemId: $itemId, quantity: $quantity, cartId: $cartId) {
      ...cartContent
    }
  }
  ${cartContentFragment}
`

export const ORDER_CART = gql`
  mutation cart_order($cartId: ID, $leadAttribution: [KeyValueInput]) {
    cart_order(cartId: $cartId, leadAttribution: $leadAttribution) {
      id
      date
      type
      status
      statusLabel
      itemCount
      currency
      subTotal
      discountAmount
      discountCode
      shipping
      tax
      total
      items {
        id
        name
        description
        image
        qty
        cost
      }
    }
  }
`

export const SET_SHIPPING_ADDRESS = gql`
  mutation cart_setShippingAddress($cartId: ID, $addressId: ID!) {
    cart_setShippingAddress(cartId: $cartId, addressId: $addressId) {
      cartId
      estimatedDelivery
      ...deliveryAddress
    }
  }
  ${deliveryAddressFragment}
`

export const ADD_SHIPPING_ADDRESS = gql`
  mutation cart_addShippingAddress(
    $cartId: ID
    $firstName: String
    $lastName: String
    $street: String
    $city: String
    $region: String
    $postcode: String
    $countryId: String
  ) {
    cart_addShippingAddress(
      cartId: $cartId
      firstName: $firstName
      lastName: $lastName
      street: $street
      city: $city
      region: $region
      postcode: $postcode
      countryId: $countryId
    ) {
      cartId
      estimatedDelivery
      deliveryAddress {
        id
        firstName
        lastName
        street
        city
        region
        postcode
        countryId
      }
    }
  }
`

export const SET_CART_PAYMENT_METHOD = gql`
  mutation cart_setPaymentMethod(
    $cartId: ID
    $id: ID!
    $setAsDefault: Boolean
  ) {
    cart_setPaymentMethod(
      cartId: $cartId
      id: $id
      setAsDefault: $setAsDefault
    ) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`

export const ADD_CART_PAYMENT_METHOD = gql`
  mutation cart_addPaymentMethod(
    $cartId: ID
    $type: String!
    $token: String!
    $name: String!
    $postcode: String!
    $setAsDefault: Boolean
  ) {
    cart_addPaymentMethod(
      cartId: $cartId
      type: $type
      token: $token
      name: $name
      postcode: $postcode
      setAsDefault: $setAsDefault
    ) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`

export const CHECK_COUPON = gql`
  query cart_checkCoupon($couponCode: String!) {
    cart_checkCoupon(couponCode: $couponCode)
  }
`

export const ADD_COUPON = gql`
  mutation cart_addCoupon($cartId: ID, $couponCode: String!) {
    cart_addCoupon(cartId: $cartId, couponCode: $couponCode) {
      ...cartContent
    }
  }
  ${cartContentFragment}
`

export const REMOVE_COUPON = gql`
  mutation cart_removeCoupon($cartId: ID) {
    cart_removeCoupon(cartId: $cartId) {
      ...cartContent
    }
  }
  ${cartContentFragment}
`

export const CART_REGISTER_GUEST = gql`
  mutation cart_registerGuest(
    $cartId: ID!
    $firstName: String!
    $email: String!
    $leadAttribution: [KeyValueInput]
  ) {
    cart_registerGuest(
      cartId: $cartId
      firstName: $firstName
      email: $email
      leadAttribution: $leadAttribution
    ) {
      details {
        ...cartDetails
      }
      content {
        ...cartContent
      }
      payment {
        id
        cardIssuer
        iconUrl
        lastFourDigits
      }
    }
  }
  ${cartDetailsFragment}
  ${cartContentFragment}
`
